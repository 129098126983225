import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF Path
const PDF = "/static/pdf/printList/price_evaluate_en.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormPriceEvaluateEN = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 15; //*

    const textCoordinates = [
      { text: props.items?.patientFullName, x: 376, y: 709 },
      { text: props.items?.hn, x: 356, y: 694 },
      { text: props.items?.patientEncounter, x: 537, y: 694 },
      { text: props.items?.patientBirthdateEN, x: 366, y: 678 },
      { text: props.items?.age.toString(), x: 542, y: 678 },
      { text: props.items?.formatGender, x: 365, y: 663 },
      { text: props.items?.signedDate, x: 125, y: 588 },
    ];

    for (const { text, x, y } of textCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }
  } catch (error) {
    console.error("Error modifying PDF: ", error);

    return {};
  }
}

export default FormPriceEvaluateEN;