import { TDocumentDefinitions } from "pdfmake/interfaces";
import { HeaderSummaryInvoiceIpd } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { getCompanyFont } from "react-lib/apps/HISV3/common/CompanyLogoForm";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE, beStringToMoment } from "react-lib/utils/dateUtils";

// start -> BE date (01/12/2567), end -> BE date (31/12/2567)
const calculateDayDuration = (start: string, end: string) => {
  if (!start || !end) {
    console.warn("Error: cannot calculate day duration!");
    return null;
  }

  const startDate = beStringToMoment(start);
  const endDate = beStringToMoment(end);

  if (!startDate.isValid() || !endDate.isValid()) {
    console.warn("Error: Invalid date format!");
    return null;
  }

  return endDate.diff(startDate, "days");
};

// format Currency "10350.00" -> "10,350.00"
const formatCurrency = (value: string) => {
  const numVal = parseFloat(value);
  if (isNaN(numVal)) return value;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numVal);
};

const FormSummaryExpenseInvoiceIpd = async (props: any): Promise<TDocumentDefinitions> => {
  // console.log("Form Summary Expense Invoice IPD: ", props);

  const data = props.detail;
  const ipdMedicalBilData = props?.detail?.ipdMedicalBil;
  const currentDate = dateToStringWithoutTimeBE(moment());
  const currentTime = moment().format("HH:mm");
  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const dayDurationCurrent = calculateDayDuration(data?.startAdmit_date, currentDate);

  const headerForm = await HeaderSummaryInvoiceIpd({
    font: getCompanyFont(),
    pageMargins: [16, 60, 16, 16],
    headerMargins: [16, 8, 16, 0],
    titleContent: [
      {
        text: "สรุปรายการใบแจ้งหนี้ค่าใช้จ่ายผู้ป่วยใน",
        style: "fieldHeaderValue",
        alignment: "center",
      },
      {
        text: "สรุปค่าใช้จ่ายที่ค้างชำระ",
        style: "fieldHeaderValue",
        alignment: "center",
      },
    ],
  });

  const { font, fontSizes, lineHeights, styles } = headerForm;

  return {
    pageSize: "A5",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      fontSize: fontSizes[14],
      lineHeight: lineHeights[1],
    },
    ...headerForm,
    styles: {
      ...styles,
      textKey: {
        bold: true,
      },
    },
    content: [
      {
        table: {
          widths: ["10%", "*", "10%", "*", "5%", "*"],
          body: [
            [
              {
                text: `AN ${data?.number}    ชื่อผู้ป่วย ${data?.patientName}`,
                style: "textKey",
                alignment: "center",
                colSpan: 6,
              },
              ...Array(5).fill({}),
            ],
            [
              { text: "หอผู้ป่วย" },
              { text: data?.divisionName || "-", alignment: "center" },
              { text: "ประเภทห้อง" },
              { text: data?.roomType || "-", alignment: "center" },
              { text: "ห้อง" },
              { text: data?.ipd_room || "-", alignment: "center" },
            ],
            [{ text: "ประเภท" }, { text: data?.coverage || "-", colSpan: 5 }, ...Array(4).fill({})],
            [
              //* วันสิ้นสุด Admit คำนวณ based on Current date
              { text: "วันที่เข้ารับการรักษา", colSpan: 2 },
              {},
              {
                text: `${data?.startDateAdmit} [${data?.startTimeAdmit}] ถึง ${data?.endDateAdmit || "-"} ${data?.endTimeAdmit ? `[${data?.endTimeAdmit}]` : ""} (จำนวนวันนอน ${data?.daysDiff} วัน)`,
                colSpan: 4,
              },
              ...Array(3).fill({}),
            ],
            [
              //* วันสิ้นสุด Admit คำนวณ based on Current date
              { text: "ค่าใช้จ่ายตั้งแต่วันที่", colSpan: 2 },
              {},
              {
                text: `${data?.startDateAdmit} [${data?.startTimeAdmit}] ถึง ${data?.endDateAdmit || "-"} ${data?.endTimeAdmit ? `[${data?.endTimeAdmit}]` : ""} (จำนวนวันคิดค่าใช้จ่าย ${data?.daysDiff} วัน)`,
                colSpan: 4,
              },
              ...Array(3).fill({}),
            ],
          ],
        },
        layout: {
          hLineColor: function (i: any, node: any) {
            return i !== node.table.body.length ? "white" : "black";
          },
          vLineColor: function (i: any, node: any) {
            return "white";
          },
        },
      },
      {
        columns: [
          { text: "", width: "*" },
          {
            stack: [
              {
                text: `ค่าใช้จ่ายคงค้างชำระ  ${
                  formatCurrency(data?.ipdMedicalBil?.total_billed_price) || ""
                } ${formatCurrency(data?.ipdMedicalBil?.total_billed_price) ? ".-" : ""}`,
                alignment: "center",
              },
              {
                text: `.........................................................`,
                alignment: "center",
                marginTop: 15,
              },
              { text: `เจ้าหน้าที่สรุปค่าใช้จ่าย`, alignment: "center" },
            ],
            width: "auto",
            alignment: "right",
            marginTop: 40,
            marginRight: 16,
          },
        ],
      },
    ],
  };
};

export default FormSummaryExpenseInvoiceIpd;
