import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, truncateText } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/assessment_form_en.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormPatientAdmissionAssessment = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 12; //*

    // Page 1
    const pageOneCoordinates = [
      { text: props.items?.patientFullName, x: 380, y: 764 },
      { text: props.items?.hn, x: 524, y: 764 },
      { text: props.items?.birthdateAd, x: 396, y: 748 },
      { text: props.items?.age.toString(), x: 474, y: 748 },
      { text: props.items?.formatGender, x: 534, y: 748 },
      { text: props.items?.patientEncounter, x: 393, y: 733 },
      { text: props.items?.formatVisitDate, x: 500, y: 733 },
      { text: props.items?.doctorFullName || "-", x: 419, y: 717 },
      { text: props.items?.ipd_room, x: 536, y: 717 },
      { text: truncateText(props.items?.allergies, customFont, fontSize, 384, 578), x: 384, y: 703 }
    ];

    // Page 2
    const pageTwoCoordinates = [
      { text: props.items?.patientFullName, x: 365, y: 761 },
      { text: props.items?.hn, x: 512, y: 761 },
      { text: props.items?.birthdateAd, x: 382, y: 745 },
      { text: props.items?.age.toString(), x: 464, y: 745 },
      { text: props.items?.formatGender, x: 524, y: 745 },
      { text: props.items?.patientEncounter, x: 383, y: 731 },
      { text: props.items?.formatVisitDate, x: 490, y: 731 },
      { text: props.items?.doctorFullName || "-", x: 409, y: 716 },
      { text: props.items?.ipd_room, x: 526, y: 716 },
      { text: truncateText(props.items?.allergies, customFont, fontSize, 372, 565), x: 372, y: 702 },
    ];

    // Page 3
    const pageThreeCoordinates = [
      { text: props.items?.patientFullName, x: 374, y: 761 },
      { text: props.items?.hn, x: 512, y: 761 },
      { text: props.items?.birthdateAd, x: 393, y: 746 },
      { text: props.items?.age.toString(), x: 470, y: 746 },
      { text: props.items?.formatGender, x: 530, y: 746 },
      { text: props.items?.patientEncounter, x: 393, y: 731 },
      { text: props.items?.formatVisitDate, x: 490, y: 731 },
      { text: props.items?.doctorFullName || "-", x: 412, y: 716 },
      { text: props.items?.ipd_room, x: 531, y: 716 },
      { text: truncateText(props.items?.allergies, customFont, fontSize, 378, 574), x: 378, y: 700 },
    ];

    drawTextOnPage(pages[0], pageOneCoordinates, customFont, fontSize);
    drawTextOnPage(pages[1], pageTwoCoordinates, customFont, fontSize);
    drawTextOnPage(pages[2], pageThreeCoordinates, customFont, fontSize);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormPatientAdmissionAssessment;