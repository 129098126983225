import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, truncateText } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/fit_travel_med_cert.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormFitTravelMedCert = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 13; //*

    // Page 1
    const pageOneCoordinates = [
      { text: props.items?.patientFullName, x: 336, y: 761 },
      { text: props.items?.hn, x: 321, y: 742 },
      { text: props.items?.patientEncounter, x: 496, y: 742 },
      { text: props.items?.birthdateAd, x: 352, y: 723 },
      { text: props.items?.age.toString(), x: 484, y: 723 },
      { text: props.items?.formatGender, x: 544, y: 723 },
      { text: props.items?.doctorFullName || "-", x: 346, y: 703 },
      { text: props.items?.formatVisitDate, x: 488, y: 703 },
      { text: truncateText(props.items?.allergies, customFont, fontSize, 341, 577), x: 341, y: 684 },
    ];

    drawTextOnPage(pages[0], pageOneCoordinates, customFont, fontSize);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormFitTravelMedCert;