import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, truncateText } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_trt_rel_info_th.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormTreatmentReleaseInfoTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 13; //*

    // Page 1
    const pageOneCoordinates = [
      { text: props.items?.patientFullName, x: 326, y: 789 },
      { text: props.items?.hn, x: 316, y: 771 },
      { text: props.items?.patientEncounter, x: 496, y: 771 },
      { text: props.items?.birthdate, x: 348, y: 751 },
      { text: props.items?.age.toString(), x: 480, y: 751 },
      { text: props.items?.formatGender, x: 539, y: 751 },
      { text: props.items?.doctorFullName || "-", x: 337, y: 731 },
      { text: props.items?.formatVisitDate, x: 489, y: 731 },
      { text: truncateText(props.items?.allergies, customFont, fontSize, 335, 572), x: 335, y: 712 },
    ];

    drawTextOnPage(pages[0], pageOneCoordinates, customFont, fontSize);
    drawTextOnPage(pages[1], pageOneCoordinates, customFont, fontSize);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormTreatmentReleaseInfoTH;