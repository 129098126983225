import { Content, ContentTable, TDocumentDefinitions } from "pdfmake/interfaces";
// import { getCompanyFont } from "react-lib/apps/HISV3/common/CompanyLogoForm";
import {
  getTextWidth,
  getTableBreakIndex,
} from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import { MONTH_OPTION } from "react-lib/utils/dateUtils";
import { bahttext } from "bahttext";

// "06/12/2567" -> "6 ธันวาคม พ.ศ. 2567"
const dateToText = (dateBe: string) => {
  if (!dateBe) {
    return "-";
  }

  const [day, month, year] = dateBe.split("/");
  const monthText = MONTH_OPTION.find((item) => item.number === month)?.text;

  return `${parseInt(day)} ${monthText} พ.ศ. ${year}`;
};

// format Currency "10350.00" -> "10,350.00"
const formatCurrency = (value: string) => {
  const numVal = parseFloat(value);
  if (isNaN(numVal)) return value;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numVal);
};

const FormListExpenseInvoiceIpd = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Form List Expense IPD: ", props);

  const data = props?.detail;
  const ipdMedicalBilData = props?.detail?.ipdMedicalBil;
  const currentDate = dateToStringWithoutTimeBE(moment());
  const currentTime = moment().format("HH:mm");

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  // Generate Data
  const generateTableBody = (detail: any, data: any) => {
    const body: any[] = [
      [
        { text: "ลำดับ", style: "textKey", alignment: "center" },
        { text: "รายการ", style: "textKey", alignment: "center" },
        { text: "รหัส กบก.", style: "textKey", alignment: "center" },
        { text: "จำนวนเงิน (บาท)", style: "textKey", alignment: "center" },
        { text: "งบสรุป", style: "textKey", alignment: "center" },
        { text: "ส่วนเกินสิทธิ (บาท)", style: "textKey", alignment: "center" },
      ],
    ];

    data?.items?.forEach((item: any, index: number) => {
      body.push([
        {
          text: `${index + 1}.`,
          // rowSpan: item?.items?.length + 1 || 1,
        },
        {
          text: item?.bill_mode,
          style: "textKey",
        },
        ...Array(2).fill({
          text: "",
        }),
        {
          text: item?.summary_price.toLocaleString("en-US", localeStringOption) || "",
          style: "textKey",
          alignment: "right",
        },
        {
          text: "",
        },
      ]);

      item?.items?.forEach((subItem: any, subIndex: number) => {
        body.push([
          {
            text: "",
          },
          {
            text: `${subItem?.product}${
              subItem?.unit_text === "วัน" ? ` วันละ ${subItem?.unitprice} บาท ` : " "
            }${subItem?.qty} ${subItem?.unit_text}`,
            style: "subItemText",
          }, //* Format
          {
            text: subItem?.claim_code || "",
            alignment: "center",
            style: "subItemText",
          },
          {
            text: subItem?.sum_price.toLocaleString("en-US", localeStringOption),
            alignment: "right",
            style: "subItemText",
          },
          {
            text: "",
          },
          {
            text: subItem.sum_non_claimable
              ? subItem.sum_non_claimable.toLocaleString("en-US", localeStringOption)
              : "",
            alignment: "right",
            style: "subItemText",
          },
        ]);
      });
    });

    body.push([
      { text: "รวมเงิน Total Amount (Baht)", colSpan: 4, style: "textKey" },
      ...Array(3).fill({}),
      {
        text: data?.summary?.total_summary_price.toLocaleString("en-US", localeStringOption),
        style: "textKey",
        alignment: "right",
      },
      {
        text: data?.summary?.total_sum_non_claimable.toLocaleString("en-US", localeStringOption),
        style: "textKey",
        alignment: "right",
      },
    ]);

    let totalSum = detail?.coverage.includes("เงินสด") ? data?.summary?.total_summary_price : data?.summary?.total_sum_non_claimable
    body.push([
      {
        text: "รวมเงินทั้งสิ้น (ตัวอักษร) Total Amount (In words)",
        colSpan: 2,
        style: "textKey",
      },
      {},
      {
        text: bahttext(parseFloat(totalSum).toFixed(2)),
        colSpan: 3,
        fontSize: 12,
        style: "textKey",
      },
      ...Array(2).fill({}),
      {
        text: formatCurrency(totalSum) || "",
        style: "textKey",
        alignment: "right",
      },
    ]);

    return body;
  };

  let docDef: TDocumentDefinitions = {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 14,
      lineHeight: 1,
    },
    styles: {
      titleText: {
        fontSize: 16,
        bold: true,
      },
      textKey: {
        bold: true,
      },
      subItemText: {
        fontSize: 12,
      },
      footerText: {
        fontSize: 10,
      },
    },
    pageMargins: [16, 30, 16, 24],
    header: (currentPage: number, countPage: number) => ({
      text: `${currentPage}/${countPage}`,
      alignment: "right",
      margin: [16, 8, 16, 0],
    }),
    footer: () => ({
      text: `พิมพ์วันที่ ${currentDate} เวลา ${currentTime} น.`,
      style: "footerText",
      alignment: "right",
      margin: [16, 0, 16, 8],
    }),
    content: [
      { text: "รายงานค่ารักษาพยาบาลผู้ป่วยใน", style: "titleText", alignment: "center" },
      {
        columns: [
          { text: `AN : ${data?.number}`, style: "textKey", width: 80 },
          { text: `HN : ${data?.hn}`, style: "textKey", width: "auto" },
        ],
      },
      // --- Patient Name and Room Detail
      {
        table: {
          widths: [
            (await getTextWidth({ text: "ชื่อผู้ป่วย", fontSize: 14 })).maxWidthText,
            "*",
            (await getTextWidth({ text: "ประเภทห้อง", fontSize: 14 })).maxWidthText,
            (await getTextWidth({ text: data?.roomType || "-", fontSize: 14 })).maxWidthText + 20,
            (await getTextWidth({ text: "หอผู้ป่วย", fontSize: 14 })).maxWidthText,
            (await getTextWidth({ text: data?.divisionName || "-", fontSize: 14 })).maxWidthText +
              20,
          ],
          body: [
            [
              {
                text: "ชื่อผู้ป่วย",
              },
              {
                text: data?.patientName,
                noWrap: true,
              },
              {
                text: "ประเภทห้อง",
              },
              {
                text: data?.roomType || "-",
                alignment: "center",
              },
              {
                text: "หอผู้ป่วย",
              },
              {
                text: data?.divisionName || "-",
                alignment: "center",
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      // --- Admit Date Info
      {
        table: {
          widths: [
            (await getTextWidth({ text: "เข้ารับการรักษาพยาบาลตั้งแต่วันที่", fontSize: 14 }))
              .maxWidthText,
            "*",
            (await getTextWidth({ text: "ถึงวันที่", fontSize: 14 })).maxWidthText,
            "*",
          ],
          body: [
            [
              {
                text: "เข้ารับการรักษาพยาบาลตั้งแต่วันที่",
              },
              {
                text: dateToText(data?.startDateAdmit),
                alignment: "center",
              },
              {
                text: "ถึงวันที่",
              },
              {
                text: dateToText(data?.endDateAdmit), //* based on current date
                alignment: "center",
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      // --- From Hospital and Number
      {
        table: {
          widths: [
            (await getTextWidth({ text: "ตามหนังสือรับรองของ", fontSize: 14 })).maxWidthText,
            "*",
            (await getTextWidth({ text: "เลขที่", fontSize: 14 })).maxWidthText,
            "25%",
          ],
          body: [
            [
              {
                text: "ตามหนังสือรับรองของ",
              },
              {
                text: data?.sendingHospital || "", //*
              },
              {
                text: "เลขที่",
              },
              {
                text: data?.sendingNumber || "", //*
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      // --- Print Date
      {
        table: {
          widths: [(await getTextWidth({ text: "ลงวันที่", fontSize: 14 })).maxWidthText, "*"],
          body: [
            [
              {
                text: "ลงวันที่",
              },
              {
                text: dateToText(currentDate),
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      // --- Diagnosis
      {
        table: {
          widths: [(await getTextWidth({ text: "ป่วยเป็นโรค", fontSize: 14 })).maxWidthText, "*"],
          body: [
            [
              {
                text: "ป่วยเป็นโรค",
              },
              {
                text: data?.formatIcd10Code && data?.formatIcd10Term ? `${data?.formatIcd10Code}  ${data?.formatIcd10Term}` : "-",
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      // --- List of Expense Invoice
      {
        table: {
          widths: ["6%", "*", "10%", "12%", "12%", "12%"],
          headerRows: 1,
          body: generateTableBody(data, ipdMedicalBilData),
        },
      },
    ],
  };

  // get Page Break Index from Table
  const resultTableDetail: any = await getTableBreakIndex(docDef, 7);

  const getRowPageBreakIndexes = (tableBody: any[]): number[] => {
    const pageBreakIndexes: number[] = [];
    let prevPageNumber: number | null = null;

    for (let i = 0; i < tableBody.length; i++) {
      const row = tableBody[i];

      if (row[1].positions && row[1].positions.length > 0) {
        const currentPageNumber = row[1].positions[0].pageNumber;

        if (prevPageNumber !== null && currentPageNumber !== prevPageNumber) {
          console.log(`[Get Page Break Indexes] Row: ${i - 1}, Line: ${i}`);
          pageBreakIndexes.push(i); // i - 1 คือ row, i คือ index line ของ table
        }

        prevPageNumber = currentPageNumber;
      }
    }

    return pageBreakIndexes;
  };

  if (resultTableDetail) {
    const tableBody = resultTableDetail.tableData.table.body;
    const pageBreakIndexes = getRowPageBreakIndexes(tableBody);

    const createLayout = (pageBreakIndexes?: number[]) => ({
      hLineColor: (i: number, node: any) => {
        const hLineUse =
          i === node.table.body.length - 1 ||
          i === node.table.body.length - 2 ||
          i === node.table.body.length ||
          i === 0 ||
          i === 1 ||
          (pageBreakIndexes && pageBreakIndexes.includes(i));

        return hLineUse ? "black" : "white";
      },
    });

    if (Array.isArray(docDef.content)) {
      const tableContent: Content = docDef.content[7];

      if (typeof tableContent === "object" && "table" in tableContent) {
        (tableContent as ContentTable).layout = createLayout(pageBreakIndexes);
      } else {
        console.warn("Content at index 7 is not table!");
      }
    } else {
      console.warn("docDef.content is not an Array!");
    }
  }

  return docDef;
};

export default FormListExpenseInvoiceIpd;
